<template>
<v-app>
  <div class="row">
    <div style="margin-left:10px" class="col-md-6">
                    <div class="form-group">
                      <label  for="last"
                        ><strong> Filtrer par : </strong> </label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selected"
                        required
                      >
                      <option  value="user">Users</option>
                      <option  value="zone">Zones</option>
                    
                      </select>
                    </div>
                  </div>
    </div>
      <div class="row">
        <div v-show="selected=='user'">
           <div v-show="selecteduser !=='all'" :style="{'background-color': selecteduser}" class="cercle"></div>
        </div>
       
       
    <div v-show="selected=='user'" class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Users </label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selecteduser"
                        required
                      >
                      <option  value="all">all</option>
                      <option
                          v-for="user in users"
                          :key="user._id"
                          v-bind:value="user.color"
                        >
                        <i
                      class="mdi mdi-delete icone text-danger"
                      style="color: red"
                      
                    />
                        <a >{{ user.name }}</a>
                        </option>
                      </select>
                    </div>
                  </div>
                  
 <v-btn
  
   v-show="selected=='user'" 
 style="margin-top:37px"
 depressed
      color="primary"
   @click="filter()"
>filtrer
</v-btn>
  
        <div  v-show="selected=='zone'"  class="marker">
                        <i
                          class="mdi mdi-map-marker-radius "
                          
                         
                          style="font-size:22px;margin-bottom:10px;"
                        >
                        </i></div>
       
    <div  v-show="selected=='zone'"  class="col-md-4">
                    <div class="form-group">
                      <label for="last"
                        >Zones </label
                      >
                      <select
                        id="inputState"
                        class="form-control"
                       v-model="selectedzone"
                        required
                      >
                      <option  value="all">all</option>
                      <option
                          v-for="zone in zones"
                          :key="zone._id"
                          v-bind:value="zone.gouvernerat"
                        >
                        <i
                      class="mdi mdi-delete icone text-danger"
                      style="color: red"
                      
                    />
                        <a >{{ zone.gouvernerat }}</a>
                        </option>
                      </select>
                    </div>
                  </div>
                  
 <v-btn
  v-show="selected=='zone'" 
  
 style="margin-top:37px"
 depressed
      color="primary"
   @click="filterzone()"
>filtrer</v-btn>
   
  </div>



<v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="eventsbase"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
         
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
             
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            
            </v-toolbar>
            <v-card-text>
              <!-- <span v-html="selectedEvent.details"></span>
               <span v-html="selectedEvent.nom_client"></span> -->
               <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>


    <v-card-title><span v-html="selectedEvent.nom_client"></span></v-card-title>
     <v-divider  class="mx-4"></v-divider>
    <v-card-text>
    

      <div class="my-4 text-subtitle-1">
          <i
                          class="mdi mdi-map-marker-radius "
                          
                         
                          style="font-size:22px;margin-bottom:10px;"
                        >
                        </i> <span v-html="selectedEvent.zone"></span>
      </div>
        <div class="my-4 text-subtitle-1">
          <i
                          class="mdi mdi-calendar-check"
                          
                         
                          style="font-size:22px;margin-bottom:10px;"
                        >
                        </i> <v-chip><span v-html="selectedEvent.end"></span></v-chip>  
      </div>
         <div class="my-4 text-subtitle-1">
          <i
                          class="mdi mdi-alarm-check"
                          
                         
                          style="font-size:22px;margin-bottom:10px;"
                        >
                        </i> <v-chip><span v-html="selectedEvent.duration"></span></v-chip>  
      </div>
         <div class="my-4 text-subtitle-1">
          <i
                          class="mdi mdi-basket"
                          
                         
                          style="font-size:22px;margin-bottom:10px;"
                        >
                        </i> <span v-html="selectedEvent.zone"></span>
      </div>

     
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    

  

    <v-card-actions>
   
    </v-card-actions>
  </v-card>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
  </v-app>
</template>
<script>
import { HTTP } from "@/axios";
  export default {
    data: () => ({
      
      selected:'',
      selecteduser:'',
      selectedzone:'',
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
       items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      users: [],
      zones: [],
      eventsbase:[],
      eventsbaseall:[],
       events2all:[
        {
            "color": "#111010FF",
            "name": "mourad",
            "start": new Date('2022-09-08'),
            "end": new Date('2022-09-08'),
            "data": "chihemek inti"
          },
          {
            "color": "#E41111FF",
            "name": "monji",
            "start": new Date('2022-09-08'),
            "end": new Date('2022-09-08'),
            "data":"bara zamer"
          },
        
        
        ],
            events2:[
          {
            "color": "#111010FF",
            "name": "mourad",
            "start": new Date('2022-09-08'),
            "end": new Date('2022-09-08'),
            "data": "chihemek inti"
          },
          {
            "color": "#E41111FF",
            "name": "monji",
            "start": new Date('2022-09-08'),
            "end": new Date('2022-09-08'),
            "data":"bara zamer"
          },
        
        
        ],
    
    }),
    created() {
    this.getusers();
    this.getevents();
    this.getzones();
   
  },

    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
       getzones() {
      HTTP
        .get("zones/getzones")
        .then((response) => {
          this.zones = response.data;
        })
        .then((err) => console.log(err));
    },
        getusers() {
      HTTP.get("users/getusersdelegue")
        .then((response) => {
          console.log(response.data);
          this.users = response.data;
        })
        .then((err) => console.log(err));
    },
         getevents() {
      HTTP.get("visites/getevents")
        .then((response) => {
         
          response.data.forEach((element, index, array) => {
             this.eventsbase.push({
           
            end: element.date,
            start:element.date,
            name: element.nom_user,
            color: element.color,
            zone: element.zone,
            nom_client:element.nom_client,
            duration:element.duration,
          })

          });
          this.eventsbaseall=this.eventsbase;
          
        })
        .then((err) => console.log(err));
    },
      filter(){
      
        if(this.selecteduser=="")
        {
          this.eventsbase=  this.eventsbaseall;
        }
       else if(this.selecteduser=="all")
        {
            this.eventsbase=  this.eventsbaseall;
        }
        else{
          this.eventsbase= this.eventsbaseall;
        let filter = 
  this.eventsbase.filter(d => 
    d.color == this.selecteduser
)

  this.eventsbase=filter;

        }

      },
      ////filtrer zone
          filterzone(){
        if(this.selectedzone=="")
        {
          this.eventsbase=  this.eventsbaseall;
        }
       else if(this.selectedzone=="all")
        {
            this.eventsbase=  this.eventsbaseall;
        }
        else{
          this.eventsbase= this.eventsbaseall;
        let filter = 
  this.eventsbase.filter(d => 
    d.zone == this.selectedzone
)

  this.eventsbase=filter;

        }

      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          console.log(event);
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
  
    },
  }
</script>
<style>
.marker {
    width:20px;
    height: 20px;
    border-radius: 20px;
    /* background: green; */
    margin-top:44px;
    margin-left:20px;
}
.cercle {
    width:20px;
    height: 20px;
    border-radius: 20px;
    /* background: green; */
    margin-top:44px;
    margin-left:20px;
}
label{
  font-size: 15px;
}


</style>